.login-body {
  background: url("../../images/bg-login.webp");
  .login-div-blur {
    background: linear-gradient(
      90deg,
      rgba(44, 44, 44, 0.68) 40%,
      rgb(0, 0, 0)
    );
    height: var(--doc-height)!important;
    min-height: 640px;
  }
  .login-wrapper .login-panel {
    .card-left {
      float: left;
      flex: 1 1 100%;
      background: url("../../images/bg-primary.webp");
      border-radius: 24px 0px 0px 24px;
      overflow: hidden;
      .content-card-left {
        .legend-card-left {
          color: #ffffff;
          .links .fa {
            color: #ffffff;
          }
          .links .fa:hover {
            color: #3bb1ff;
          }
        }
      }
    }
    .card-right {
      float: right;
      padding: 20px 40px;
      flex: 1 1 100%;
      .p-input-filled .p-float-label input {
        width: 100%;
        padding: 12px 10px;
        height: 45px;
      }
      .grid .col {
        .animated-check {
          height: 138px;
        }
        button {
          width: 85%;
          max-width: 247px;
          font-size: 0.875rem !important;
          height: 40px;
        }
        p {
          font-weight: 500;
          margin: 0;
        }
        .select-lang {
          color: var(--text-color);
        }
        .text-register {
          color: var(--primary-color);
        }
      }
      a {
        text-decoration: none;
      }
    }
  }
}

//Responsie login

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
  .login-body .login-wrapper .login-panel {
    width: 90%!important;
    font-size: 12px;
    .card-left {
      display: none;
    }
    .card-right {
      float: unset;
      width: 100% !important;
      padding: 10px 20px;
    }
    .logo img{
      margin-top: 1.5rem;
      height: 3rem;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .login-body .login-wrapper .login-panel {
    overflow-y: scroll;
    width: 90%!important;
    font-size: 12px;
    .card-left {
      display: none;
    }
    .card-right {
      float: unset;
      width: 100% !important;
      padding: 10px 20px;
    }
  }
}


/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1920px) {
  .login-body .login-wrapper .login-panel {
    font-size: 15px;
  }
}
