.clearfix:after {
  content: "";
  display: block;
  clear: both;
}
.card {
  background: var(--surface-a);
  color: var(--text-color);
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(41, 50, 65, .06);
  -moz-border-radius: 24px;
  -webkit-border-radius: 24px;
  border-radius: 24px;
  margin: 1rem;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.layout-wrapper{
  height: 100%;
}

.layout-wrapper .topbar {
  height: 62px;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  background: var(--surface-a);
  z-index: 12;
}

.layout-wrapper .topbar #menu-button {
  text-align: center;
  width: 59px;
  height: 60px;
  line-height: 60px;
  color: #777777;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout-wrapper .topbar #menu-button span {
  font-size: 36px;
  line-height: inherit;
  transition: all 0.3s;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.layout-wrapper .topbar .morpheus-logo-link {
  vertical-align: middle;
  line-height: 60px;
  margin-left: 20px;
}

.layout-wrapper .topbar .morpheus-logo-link .morpheus-logo {
  vertical-align: middle;
  height: 46px;
  margin-top: 1px;
}

.layout-wrapper .topbar .morpheus-logo-link .morpheus-logo-text {
  font-size: 22px;
  font-weight: 700;
  padding-left: 5px;
}

.layout-wrapper .topbar #topbar-menu-button {
  font-size: 28px;
  margin: 12px 20px 0 0;
  display: none;
  float: right;
  color: #ffffff;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.layout-wrapper .topbar #topbar-menu-button:hover {
  color: #494949;
}

.layout-wrapper .topbar .topbar-menu {
  display: flex;
  height: 100%;
  float: right;
  margin: 0 !important;
  padding: 0px 20px;
  align-items: center;
  list-style-type: none;
}

.layout-wrapper .topbar .topbar-menu > li {
  float: left;
  position: relative;
  margin-left: 25px;
}

.layout-wrapper .topbar .topbar-menu > li > a {
  position: relative;
  display: block;
  color: #afafaf;
}

.layout-wrapper .topbar .topbar-menu > li > a .topbar-icon {
  font-size: 28px;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.layout-wrapper .topbar .topbar-menu > li > a .topbar-icon:hover {
  color: #777777;
}

.layout-wrapper .topbar .topbar-menu > li > a .topbar-item-name {
  display: none;
}

.layout-wrapper .topbar .topbar-menu > li > a .topbar-badge {
  position: absolute;
  right: -5px;
  top: -5px;
  background-color: #50aaf3;
  color: #ffffff;
  padding: 2px 4px;
  display: block;
  font-size: 12px;
  line-height: 12px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.layout-wrapper .topbar .topbar-menu > li.profile-item .profile-text {
  display: none;
}

.layout-wrapper .topbar .topbar-menu > li.profile-item .profile-image {
  display: inline-block;
  vertical-align: middle;
}

.layout-wrapper .topbar .topbar-menu > li.profile-item .profile-info {
  display: inline-block;
  vertical-align: middle;
  max-width: 100px;
  margin-top: -5px;
}

.layout-wrapper
  .topbar
  .topbar-menu
  > li.profile-item
  .profile-info
  .topbar-item-name {
  display: block;
}

.layout-wrapper
  .topbar
  .topbar-menu
  > li.profile-item
  .profile-info
  .topbar-item-name.profile-name {
  font-size: 11px;
}

.layout-wrapper
  .topbar
  .topbar-menu
  > li.profile-item
  .profile-info
  .topbar-item-name.profile-role {
  font-size: 10px;
  color: #e6e6e6;
}

.layout-wrapper .topbar .topbar-menu > li.search-item {
  position: relative;
  color: #777777;
}

.layout-wrapper .topbar .topbar-menu > li.search-item i {
  position: absolute;
  left: 6px;
  top: 7px;
}

.layout-wrapper .topbar .topbar-menu > li.search-item input {
  padding-left: 20px;
}

.layout-wrapper .topbar .topbar-menu > li > ul {
  position: absolute;
  top: 45px;
  right: 0px;
  display: none;
  width: 250px;
  background-color: #f7f7f7;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
  list-style-type: none;
  margin: 0;
  padding: 8px 0;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  z-index: 1027;
}

.layout-wrapper .topbar .topbar-menu > li > ul a {
  padding: 0.5em 1em;
  display: block;
  width: 100%;
  box-sizing: border-box;
  color: #777777;
  text-align: left;
}

.layout-wrapper .topbar .topbar-menu > li > ul a .fa {
  margin-right: 8px;
}

.layout-wrapper .topbar .topbar-menu > li > ul a img {
  margin-right: 8px;
}

.layout-wrapper .topbar .topbar-menu > li > ul a .fa,
.layout-wrapper .topbar .topbar-menu > li > ul a img,
.layout-wrapper .topbar .topbar-menu > li > ul a span {
  vertical-align: middle;
}

.layout-wrapper .topbar .topbar-menu > li > ul a .topbar-submenuitem-badge {
  background-color: #50aaf3;
  padding: 1px 6px;
  color: #ffffff;
  float: right;
}

.layout-wrapper .topbar .topbar-menu > li > ul a:hover {
  background-color: #e3e3e3;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.layout-wrapper .topbar .topbar-menu > li.active-topmenuitem > ul {
  display: block;
}

.layout-wrapper.layout-wrapper-menu-active #menu-button span {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.layout-wrapper.layout-wrapper-menu-active.layout-wrapper-menu-active-restore
  .layout-sidebar {
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}

.layout-wrapper.layout-wrapper-menu-active.layout-wrapper-menu-active-restore
  .layout-content {
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}

.layout-wrapper.layout-wrapper-menu-active.layout-wrapper-menu-active-restore
  #menu-button
  span {
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}

.layout-wrapper .layout-sidebar {
  position: fixed;
  height: 100%;
  width: 60px;
  background-color: var(--surface-a);
  z-index: 301;
  -moz-transition: width 0.3s;
  -o-transition: width 0.3s;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
}

.layout-wrapper .layout-sidebar .layout-tabmenu {
  height: 100%;
  width: 60px;
  position: relative;
}

.layout-wrapper .layout-sidebar .layout-tabmenu .layout-tabmenu-nav {
  padding: 0;
  margin: 0;
  text-align: center;
  display: block;
}

.layout-wrapper .layout-sidebar .layout-tabmenu .layout-tabmenu-nav li {
  list-style-type: none;
  text-align: center;
  box-sizing: border-box;
}

.layout-wrapper .layout-sidebar .layout-tabmenu .layout-tabmenu-nav li a {
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  display: block;
  position: relative;
}

.layout-wrapper .layout-sidebar .layout-tabmenu .layout-tabmenu-nav li i {
  line-height: 50px;
  font-size: 22px;
  color: var(--text-color);
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-nav
  li
  .layout-tabmenu-tooltip {
  display: none;
  padding: 0 5px;
  position: relative;
  left: 60px;
  top: -38px;
  z-index: 101;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-nav
  li
  .layout-tabmenu-tooltip
  .layout-tabmenu-tooltip-text {
  padding: 3px 10px;
  background-color: #878787;
  color: #ffffff;
  min-width: 75px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-nav
  li
  .layout-tabmenu-tooltip
  .layout-tabmenu-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #878787;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-nav
  li.active-item
  a
  i {
  color: var(--primary-color);
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-nav
  li.active-item
  a::before {
  content: "";
  width: 4px;
  height: 12px;
  display: block;
  border-radius: 0px 3px 3px 0px;
  position: absolute;
  left: 0;
  bottom: 42%;
  background-color: var(--primary-color);
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-nav
  li.active-item
  a
  .layout-tabmenu-tooltip {
  left: 60px;
}

.layout-wrapper .layout-sidebar .layout-tabmenu .layout-tabmenu-contents {
  position: absolute;
  width: 230px;
  left: 60px;
  top: 0;
  height: 100%;
  background-color: var(--surface-a);
  display: none;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-tabmenu-content {
  height: 100%;
  display: none;
  padding: 63px 0px 6px 6px;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-tabmenu-content.layout-tabmenu-content-active {
  display: block;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-tabmenu-content
  a
  .selected-tab {
  color: #ffffff;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-tabmenu-content
  .layout-submenu-title {
  color: var(--text-color-secondary);
  border-bottom: 2px solid var(--surface-d);
  padding: 4px;
  margin-right: 6px;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-tabmenu-content
  .layout-submenu-title
  span {
  letter-spacing: 1px;
  font-size: 10px;
  font-weight: bold;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-submenu-content {
  height: 100%;
  overflow: auto;
  padding-top: 6px;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-submenu-content
  .ui-scrollpanel {
  background: transparent;
  border-radius: 0;
  border: none;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-submenu-content
  .ui-scrollpanel
  .ui-scrollpanel-bar-y {
  background-color: #aaa;
  opacity: 0.4;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-submenu-content
  .ui-scrollpanel
  .ui-scrollpanel-hidden {
  display: block;
  visibility: hidden;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-submenu-content
  .ui-scrollpanel
  .ui-scrollpanel-content
  > *:last-child {
  overflow: visible;
  padding-bottom: 170px;
  padding-right: 18px;
  width: 212px;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-submenu-content
  .navigation-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding: 0 6px 0 0;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-submenu-content
  .navigation-menu
  li
  > a {
  display: block;
  padding: 6px;
  border-radius: 2px;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-submenu-content
  .navigation-menu
  li
  > a
  i:first-child {
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  margin-right: 4px;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-submenu-content
  .navigation-menu
  li
  > a
  .ui-menuitem-toggler {
  float: right;
  margin-top: 2px;
  -moz-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-submenu-content
  .navigation-menu
  li
  > a:hover {
  background-color: #525252;
  color: #ffffff;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-submenu-content
  .navigation-menu
  li
  > a.active-menuitem-routerlink {
  color: #d4eeff;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-submenu-content
  .navigation-menu
  li
  ul {
  margin: 0;
  padding: 0 0 0 18px;
  list-style-type: none;
  overflow: hidden;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-submenu-content
  .navigation-menu
  li.active-menuitem
  > a {
  color: #50aaf3;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-submenu-content
  .navigation-menu
  li.active-menuitem
  > a
  i:first-child {
  color: #50aaf3;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-submenu-content
  .navigation-menu
  li.active-menuitem
  > a
  .ui-menuitem-toggler {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-submenu-content
  .navigation-menu
  li.orange-badge
  > a
  .menuitem-badge {
  background-color: #f1b009;
  color: #777777;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-submenu-content
  .navigation-menu
  li.purple-badge
  > a
  .menuitem-badge {
  background-color: #985edb;
  color: #777777;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-submenu-content
  .navigation-menu
  li.blue-badge
  > a
  .menuitem-badge {
  background-color: #50aaf3;
  color: #777777;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-submenu-content
  .navigation-menu
  .menuitem-badge {
  position: relative;
  float: right;
  display: inline-block;
  width: 16px;
  height: 16px;
  text-align: center;
  background-color: #50aaf3;
  color: #ffffff;
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.layout-wrapper .layout-content {
  height: 100%;
  padding: 62px 0px 0px 0px;
  /* padding: 62px 0px 0px 0px; */
  margin-left: 60px;
  transition: margin-left 0.3s;
}

.layout-wrapper .layout-content .footer {
  padding: 8px 14px;
}

.layout-wrapper .layout-content .footer span {
  display: inline-block;
  padding-bottom: 10px;
}

.layout-wrapper .layout-content .footer .link-divider {
  margin: 0 10px;
}

.layout-wrapper .layout-content .footer .footer-links .first {
  margin: 0;
}

.layout-wrapper .layout-content .footer .footer-links a {
  color: #777777;
  margin-left: 8px;
}

.layout-wrapper .layout-content .footer .footer-links a:hover {
  color: #494949;
}

@media (min-width: 1025px) {
  .layout-wrapper.layout-wrapper-menu-active .layout-sidebar {
    width: 290px;
  }

  .layout-wrapper.layout-wrapper-menu-active
    .layout-sidebar
    .layout-tabmenu
    .layout-tabmenu-contents {
    display: block;
    -moz-transition: width 0.3s;
    -o-transition: width 0.3s;
    -webkit-transition: width 0.3s;
    transition: width 0.3s;
  }

  .layout-wrapper.layout-wrapper-menu-active .layout-content {
    margin-left: 260px;
  }

  .layout-wrapper.layout-overlay-menu.layout-wrapper-menu-active
    .layout-content {
    margin-left: 60px;
  }
}

@media (max-width: 1024px) {
  .layout-wrapper.layout-wrapper-menu-active .layout-sidebar {
    width: 290px;
    -moz-transition: width 0.3s;
    -o-transition: width 0.3s;
    -webkit-transition: width 0.3s;
    transition: width 0.3s;
  }

  .layout-wrapper.layout-wrapper-menu-active
    .layout-sidebar
    .layout-tabmenu
    .layout-tabmenu-contents {
    display: block;
    -moz-transition: width 0.3s;
    -o-transition: width 0.3s;
    -webkit-transition: width 0.3s;
    transition: width 0.3s;
  }
}

.layout-tabmenu-content .inbox-tab ul {
  padding: 0 6px;
  margin: 0;
  list-style-type: none;
}

.layout-tabmenu-content .inbox-tab ul li {
  padding: 12px 0;
}

.layout-tabmenu-content .inbox-tab ul li:first-child {
  margin-top: 12px;
}

.layout-tabmenu-content .inbox-tab ul li img {
  float: left;
  margin-right: 8px;
}

.layout-tabmenu-content .inbox-tab ul li .name {
  font-weight: bold;
  float: left;
  color: #c4c4c4;
}

.layout-tabmenu-content .inbox-tab ul li .message {
  float: left;
}

.layout-tabmenu-content .inbox-tab .inbox-labels {
  margin: 20px 6px 0 6px;
  color: #c4c4c4;
}

.layout-tabmenu-content .inbox-tab .inbox-labels > span {
  font-weight: bold;
}

.layout-tabmenu-content .inbox-tab .inbox-labels ul {
  margin-top: 10px;
}

.layout-tabmenu-content .inbox-tab .inbox-labels ul li {
  padding: 6px;
}

.layout-tabmenu-content .inbox-tab .inbox-labels ul li .inbox-label-badge {
  background-color: #777777;
  color: #ffffff;
  padding: 2px 6px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  float: right;
}

.layout-tabmenu-content
  .inbox-tab
  .inbox-labels
  ul
  li
  .inbox-label-badge-green {
  background-color: green;
  color: #ffffff;
  padding: 2px 6px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  float: right;
}

.layout-tabmenu-content
  .inbox-tab
  .inbox-labels
  ul
  li
  .inbox-label-badge-yellow {
  background-color: darkgoldenrod;
  color: #ffffff;
  padding: 2px 6px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  float: right;
}

.layout-tabmenu-content .inbox-tab .inbox-labels ul li .inbox-label-badge-red {
  background-color: darkred;
  color: #ffffff;
  padding: 2px 6px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  float: right;
}

.center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.layout-tabmenu-content .update-tab {
  padding: 0px 4px 0px 8px;
}

.layout-tabmenu-content .update-tab p {
  padding: 12px 0;
}

.layout-tabmenu-content .update-tab .percentage-indicator {
  margin-top: 10px;
}

.layout-tabmenu-content .update-tab .percentage-indicator span {
  float: left;
}

.layout-tabmenu-content .update-tab .percentage-indicator span:first-child {
  color: #c4c4c4;
}

.layout-tabmenu-content .update-tab .percentage-indicator span:last-child {
  float: right;
}

.layout-tabmenu-content .update-tab .progress-bar {
  width: 100%;
  height: 10px;
  margin-top: 35px;
}

.layout-tabmenu-content .update-tab .progress-bar .progress-bar-indicator-1 {
  background-color: #92f5a1;
  width: 84%;
  height: inherit;
}

.layout-tabmenu-content .update-tab .progress-bar .progress-bar-indicator-2 {
  background-color: #c4e3c9;
  width: 58%;
  height: inherit;
}

.layout-tabmenu-content .update-tab .progress-bar .progress-bar-indicator-3 {
  background-color: #c8cfc9;
  width: 44%;
  height: inherit;
}

.layout-tabmenu-content .update-tab .progress-bar .progress-bar-indicator-4 {
  background-color: #dedede;
  width: 37%;
  height: inherit;
}

.layout-tabmenu-content .calendar-tab ul {
  padding: 0 6px;
  margin: 0;
  list-style-type: none;
}

.layout-tabmenu-content .calendar-tab ul li {
  padding: 12px 0;
}

.layout-tabmenu-content .calendar-tab ul li:first-child {
  margin-top: 12px;
}

.layout-tabmenu-content .calendar-tab ul li .calendar-event-date {
  float: left;
  color: #ffffff;
  background-color: #50aaf3;
  width: 48px;
  height: 48px;
  text-align: center;
  padding: 6px 0 0 0;
  font-size: 14px;
  font-weight: 700;
  box-sizing: border-box;
  margin-right: 8px;
  border-radius: 50%;
}

.layout-tabmenu-content .calendar-tab ul li .calendar-event-date span {
  width: 100%;
  display: inline-block;
}

.layout-tabmenu-content .calendar-tab ul li .calendar-event-detail {
  float: left;
}

.layout-tabmenu-content
  .calendar-tab
  ul
  li
  .calendar-event-detail
  .calendar-event-title {
  font-weight: 700;
  display: block;
  color: #c4c4c4;
}

.layout-tabmenu-content .calendar-tab ul li .calendar-event-detail i {
  margin-right: 4px;
}

.layout-tabmenu-content
  .calendar-tab
  ul
  li
  .calendar-event-detail
  .calendar-event-location {
  margin-right: 4px;
  font-weight: bold;
}

.layout-tabmenu-content
  .calendar-tab
  ul
  li
  .calendar-event-detail
  .calendar-event-rsvp {
  display: block;
}

.layout-tabmenu-content
  .calendar-tab
  ul
  li
  .calendar-event-detail
  .calendar-event-rsvp.calendar-event-rsvp-yes {
  color: #50aaf3;
}

.layout-tabmenu-content
  .calendar-tab
  ul
  li
  .calendar-event-detail
  .calendar-event-rsvp.calendar-event-rsvp-maybe {
  color: #fbc948;
}

.layout-tabmenu-content .projects-tab ul {
  padding: 0 6px;
  margin: 0;
  list-style-type: none;
}

.layout-tabmenu-content .projects-tab ul:hover {
  padding: 0 6px;
  margin: 0;
  list-style-type: none;
}

.layout-tabmenu-content .projects-tab ul li {
  padding: 12px 0;
}

.layout-tabmenu-content .projects-tab ul li:first-child {
  margin-top: 12px;
}

.layout-tabmenu-content .projects-tab ul li i {
  font-size: 26px;
  margin-right: 8px;
  float: left;
  width: 32px;
}

.layout-tabmenu-content .projects-tab ul li .project-title {
  padding-top: 5px;
  font-weight: 700;
  color: var(--text-color-secondary);
}

.layout-tabmenu-content .projects-tab ul li span {
  float: left;
  display: block;
  color: var(--text-color-secondary);
}

.layout-tabmenu-content .projects-tab ul li .project-progressbar {
  width: 100px;
  float: left;
  background-color: #545b61;
  margin-top: 4px;
}

.layout-tabmenu-content
  .projects-tab
  ul
  li
  .project-progressbar
  .project-progressbar-value {
  background-color: #50aaf3;
  height: 4px;
}

.container-nav {
  margin-left: 20%;
}

.nav-side-a {
  text-decoration: none;
  color: var(--text-color-secondary);
}

.nav-side-text {
  font-size: 13px;
}
