//STYLES LOGIN
@use "assets/styles/sass/login.scss";
@use "assets/styles/css/styles-graphics.css";
//
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
@import 'swiper/scss';
$font-stack: Noto Sans, sans-serif;
:root {
  --doc-height: 100%;
}

html,
body {
 padding: 0;
 margin: 0;
 height: var(--doc-height);
 min-height: var(--doc-height);
}

body {
  font: var(--global-font-size) $font-stack;
  background-color: var(--surface-ground);
}

body::-webkit-scrollbar {
  width: 9px;
  height: 9px; 
}

body::-webkit-scrollbar-track {
  background: var(--surface-ground); /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: var(--text-color-secondary); /* color of the scroll thumb */
  border-radius: 3px; /* roundness of the scroll thumb */
}

::-webkit-resizer{
  background: transparent;
}

::-webkit-scrollbar-corner{
  background: transparent;
}

//PARTICLE JS
#tsparticles canvas {
  z-index: 2 !important;
  background-color: rgba(255, 255, 255, 0) !important;
}

// FIX INPUT PASSWORD
// .fix-icon-password .p-element {
//   div {
//     width: 100%;
//   }
//   i {
//     position: absolute;
//     top: 50%;
//     margin-top: -0.5rem;
//     right: 0.5rem;
//     color: #83888f;
//     cursor: pointer;
//   }
// }

.v-hidden {
  visibility: hidden;
}

.errMessage {
  width: 100%;
  margin-top: 10px;
}

.p-toast {
  z-index: 4;
}

.p-sidebar .p-sidebar-header {
  display: none;
}

.w-button {
  min-width: 120px;
}

::ng-deep .p-dialog {
  max-height: 98% !important;
}

.p-sidebar-right {
  top: 10px !important;
  bottom: 10px !important;
  overflow: hidden !important;
  height: calc(var(--doc-height) - 20px) !important;
  border-radius: 24px !important;
  padding: 10px !important;
  right: 10px !important;
}

.p-sidebar-left {
  top: 10px !important;
  bottom: 10px !important;
  overflow: hidden !important;
  height: calc(var(--doc-height) - 20px) !important;
  border-radius: 24px !important;
  padding: 10px !important;
  left: 10px !important;
}

.p-avatar img {
  object-fit: cover;
}

.pac-container {
  z-index: 9999 !important;
}

.red-span {
  background-color: #ff0000 !important;
}

.orange-span {
  background-color: #f18400 !important;
}

.yellow-span {
  background-color: #eef200 !important;
  color: black!important;
}

.lemon-span {
  background-color: #1ded02 !important;
}

.green-span {
  background-color: #45a518 !important;
}

.iconred {
  color: red;
}

.icongreen {
  color: green;
}

.iconyellow {
  color: #eef200;
}

.dialog-crop-img {
  .p-dialog-content {
    padding-bottom: 0!important;
    border-radius: 0px 0px 6px 6px!important;
  }
}

.dialog-no-header{
  .p-dialog-content {
    border-radius: 6px 6px 0px 0px!important;
  }
}

.table-reorder.p-datatable .p-datatable-tbody > tr > td{
  border: 0px solid var(--surface-d)!important;
  border-width: 0!important;
}

.table-reorder.p-datatable .p-datatable-tbody > tr{
  border: 1px solid var(--surface-d)!important;
  border-width: 1px 1px 1px 1px!important;
  margin-bottom: 0.5rem!important;
  border-radius: 6px!important;
}

.p-inputtext{
  width: 100%;
}

.p-datatable{
  font-size: 10.5px;
}

.p-datatable .p-datatable-header {
  border: unset;
  padding-top: 0;
}

.p-paginator {
  border:unset;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 4px 2px!important;
  background: var(--surface-a);
}

.p-datatable-tbody > tr > td{
  height: 47.6px;
  padding: 4px 2px!important;
}


////////////////////

// .p-splitbutton.p-button-rounded > .p-button {
//   border-radius: 2rem;
// }

.required {
  font-size: 12px;
  font-weight: bold;
  color: var(--pink-500);
  padding-left: 5px;
}

.p-calendar-p-0 .p-datepicker table td{
  padding: 0!important;
}

/*************************************************************************
  MAIN CONTAINER
**************************************************************************/

div.container-layout {
  height: 100%;
  overflow: hidden;
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;

  grid-template-areas:
      "ads ads ads"
      "left-aside main-section right-aside";
  grid-template-rows: 100px 1fr;
  grid-template-columns: 216px 1fr 312px;
}

/**************************************************************************
  main container > LEFT ASIDE
**************************************************************************/

.container-layout > div#left-aside-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.container-layout aside#left-aside {
  grid-area: left-aside;
  width: 319px;
}

/**************************************************************************
  main container > MAIN SECTION
**************************************************************************/

.container-layout > div#main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

div.container-layout main#main-section {
  grid-area: main-section;
  margin: 0 25px;
  width: 550px;
}

/**************************************************************************
  main container-layout > RIGHT ASIDE
**************************************************************************/

div.container-layout aside#right-aside {
  grid-area: right-aside;
  width: 319px;
}
.text-color{
  color: var(--text-color);
}

.p-datatable .p-datatable-loading-overlay{
  z-index: 3!important;
  border-radius: 10px;
}

.actual,
.invoice,.observed {
  color: #fff;
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 3px;
  width: auto;
  font-weight: 700;
  display: inline-block;
}

.actual {
  background: #59c429;
}

.invoice {
  background: #000000;
}

.observed {
  background: #000000;
}

.emoji-mart-scroll::-webkit-scrollbar {
  width: 12px;
  height: 12px; 
}

.emoji-mart-scroll::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.emoji-mart-scroll::-webkit-scrollbar-thumb {
  background-color: var(--surface-400); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.emoji-mart {
  display: block!important;
}

.p-dataview .p-paginator-bottom{
  padding-bottom: 0;
}

.scrollStyle{
    align-content: flex-start;
}

.p-scrollpanel-bar-x{
  display: none;
}

.apexcharts-reset-icon{
  color: var(--apex)!important;
}

.apexcharts-icon-new{
  cursor: pointer!important;
  font-size: 16px!important;
  color: var(--apex)!important;
  text-align: center!important;
  margin-left: 5px;
  margin-right: 2px;
}

.apexcharts-icon-new:hover{
  color: var(--bold-text)!important;
}

.p-overlaypanel:after{
  display: none;
}

.p-overlaypanel:before {
  display: none;
}

@property --angle {
  syntax: "<angle>";
  initial-value: 45deg;
  inherits: false
}

.card-shadow {
  --angle: 45deg;
  background: linear-gradient(var(--angle),#29abe2,#8e98df 25%,#cda9e8 48.44%,#f2bbb7 72.92%,#fffb93);
  border-radius: 24px;
  padding: 4px;
  animation: border-animation-data 2s linear infinite;
  margin: 1rem;
}

.card-shadow .container-shadow {
  color: var(--text-color);
  padding: 20px;
  background-color: var(--surface-a);
  border-radius: 24px;
  margin: 0;
}

.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}

.message-important{
  margin:10px 0px;
  background: rgb(234 84 85 / 20%);
  border: 2px solid var(--pink-500);
  padding: 10px 30px;
  border-radius: 10px;
}

.message-success{
  margin:10px 0px;
  background: rgb(41 199 111 / 20%);
  border: 2px solid var(--green-500);
  padding: 10px 30px;
  border-radius: 10px;
}

.message-info{
  margin:10px 0px;
  background: rgb(62 201 214 / 20%);
  border: 2px solid var(--cyan-500);
  padding: 10px 30px;
  border-radius: 10px;
}

.apexcharts-toolbar{
  z-index: 1!important;
}

.apexcharts-xaxistooltip, .apexcharts-yaxistooltip{
  z-index: 1!important;
}

@keyframes border-animation-data {
  0% {
    --angle: 45deg;
  }
  100% {
    --angle: 405deg;
  }
}

@media only screen and (min-width: 768px) {
  .scrollStyle::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }
  
  .scrollStyle::-webkit-scrollbar-track {
    background: transparent;  /* color of the tracking area */
  }
  
  .scrollStyle::-webkit-scrollbar-thumb {
    background-color: var(--text-color-secondary); /* color of the scroll thumb */
    border-radius: 3px; /* roundness of the scroll thumb */
  }  

  textarea::-webkit-scrollbar {
    width: 9px;
    height: 9px; 
  }
  
  textarea::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }
  
  textarea::-webkit-scrollbar-thumb {
    background-color: var(--text-color-secondary); /* color of the scroll thumb */
    border-radius: 3px; /* roundness of the scroll thumb */
  }
}

.sidebar-35vw{
  width: 35vw;
}

.sidebar-49vw{
  width: 49vw;
}

.sidebar-75vw{
  width: 75vw;
}

.sidebar-100vw{
  width: calc(100% - 20px);
}

.formatted-text {
  white-space: pre-wrap; /* Preserva saltos de línea y espacios */
  word-wrap: break-word; /* Ajusta el texto para evitar desbordamiento */
}

.invalid-form-button {
  border: 1px solid var(--invalid-form-button) !important;
}

.p-tree-empty-message{
  padding: 1rem;
}

/* Ajustar el ancho del calendario en pantallas pequeñas */
.fc {
  width: 100% !important;
  font-size: 14px;
}

.fc-timegrid {
  -webkit-overflow-scrolling: touch;
  touch-action: manipulation;
}

/* Ajuste para que los slots tengan altura suficiente en móviles */
.fc-timegrid-slot {
  min-height: 40px; /* Ajusta según tus necesidades */
  cursor: pointer;
  touch-action: manipulation;
}

/* Asegura que el calendario ocupe todo el ancho en móviles */
.fc {
  width: 100% !important;
  height: auto !important;
}

/* Ajustes específicos para la vista de día en móviles */
@media (max-width: 768px) {
  .fc-timegrid-slots {
    min-height: 300px;
  }
}

/* Ocultar elementos innecesarios en dispositivos móviles */
@media (max-width: 768px) {
  .fc-header-toolbar {
    flex-direction: column;
    align-items: center;
  }

  .fc-toolbar-chunk {
    margin-bottom: 10px;
  }

  .fc .fc-timeGrid-col {
    min-width: auto;
  }
}

@media only screen and (max-width: 768px) {
  .card-mobile {
    padding: 0!important;
    background: unset!important;
    box-shadow: unset!important;
  }
}

@media only screen and (max-width: 1200px) {
  .sidebar-75vw, .sidebar-49vw ,.sidebar-35vw{
    width: calc(100% - 20px);
  }
}